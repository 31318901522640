.event-card {
  display: flex;
  flex-direction: column;
  /* margin: 10px; */
  cursor: pointer;
}
.swiper-pagination-bullets {
  display: none !important;
}
.event-card-section {
  position: relative;
  background-size: cover;
  background-position: center;
  max-width: 374px;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.event-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /*Overlayeffect*/
  color: white;
  min-height: 300px !important;
  padding: 15px;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.event-title {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  margin-bottom: 4px;
}

.event-description {
  /* font-size: 16px;
  text-align: center;
  line-height: 20px; */
  font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  color: #c9ffc0;
}

.event-details {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 100%;
}

.event-details-sub {
  display: flex;
  gap: 10px;
  width: 100%;
}

.event-price {
  color: #c9ffc0;
}

.event-price,
.event-item,
.event-date {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.join-btn {
  color: white;
  border: none;
  padding: 8px 20px;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  font-weight: 700;
  background-color: #9b7bff;
  transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.join-btn:hover {
  background-color: #9b7bff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.event-card-content {
  min-height: 350px;
}

.slider-index-ui {
  position: absolute;
  bottom: -30px;
  left: -0px;
  font-size: 70px;
  color: black;
  text-shadow: 2px 2px 0px white, /* Right shadow */ -2px -2px 0px white,
    /* Left shadow */ 2px -2px 0px white, /* Top shadow */ -2px 2px 0px white; /* Bottom shadow */
}

.card-title {
  font-weight: 700;
  background: linear-gradient(
    180deg,
    #954adf 31.25%,
    rgba(152, 58, 247, 0.36) 114.58%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 18px;
}

@media screen and (max-width: 768px) {
  .event-card {
    max-width: 450px;
  }
  .event-card-content {
    padding: 10px;
  }

  .event-card-content {
    min-height: 306px;
  }

  .event-title {
    font-size: 21px;
  }

  .event-description {
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .event-card {
    max-width: 350px;
    /* margin: 10px auto; */
  }
}
