.col {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.container-8 {
  font-size: 100px;
  font-weight: 900;
  /* line-height: 112px; */
  text-align: start;
  background: linear-gradient(
    180deg,
    #954adf 31.25%,
    rgba(152, 58, 247, 0.36) 114.58%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.mrgin_top_hero {
  margin-top: 60px;
}
.gl-hero-lead-in {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  text-align: start;
}

h1 {
  letter-spacing: -0.4px;
  color: #fff;
  font-size: 122px;
  font-weight: 900;
  /* line-height: 116px; */
}

.gl-hero-body {
  width: 100%;
  max-width: 540px;
  color: #cfcfcf;
  font-size: 19px;
  line-height: 32px;
  /* margin-left: 12rem; */
  align-items: center;
  justify-content: center;
  z-index: -1;
  text-align: start;
}

.btn_login {
  justify-content: start !important;
  display: flex !important;
}
.cta-btn {
  /* float: left; */
  background-color: #fff;
  text-align: center;
  /* background-color: #053df5; */
  border-radius: 8px;
  padding: 16px 48px;
  font-family: Centra No\.2, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border: 0;
  outline: 0;
  /* display: block; */
}

.cta-btn:hover {
  background-color: #ebefff;
  color: #000;
}

.cta-btns {
  /* grid-column-gap: 8px; */
  color: #fff;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px 48px;
  font-size: 18px;
  font-weight: 500;
  transition: background-color 0.2s;
  /* display: flex; */
  position: relative;
  width: 206px;
}

.cta-btns:hover {
  opacity: 0.65;
}

.planet {
  margin-top: 17rem;
  margin-left: -20px;
  opacity: 0.7;
  transform: scale(0.8);
}

.planets {
  margin-top: 15rem;
  /* margin-left: 165px; */
  text-align: end;
  justify-content: flex-end;
  opacity: 0.7;
  transform: scale(0.8);
  transform: translateX(6%);
}
.bg_drop {
  position: relative;
  /* overflow: hidden; */
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  backdrop-filter: blur(12px);
  /* background: transparent; Set background to transparent */
  /* background: linear-gradient(
    180deg,
    rgba(81, 11, 152, 0.27) 0%,
    rgba(107, 87, 154, 0) 90.15%
  ); */
  /* box-shadow: 0px 0px 60px 6px rgba(64, 116, 23, 0.728281);
  ; */
  box-shadow: 0px -16px 38px 1px rgba(64, 116, 23, 0.728281);
  /* background-image: url("../../assets/backgrounds/Rectangle51.png"); */
  background: linear-gradient(
    to bottom right,
    rgb(108 24 180 / 20%),
    rgba(192, 132, 252, 0.2)
  );
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  margin-top: 20px;
}
.mrgin_top {
  margin-top: 120px;
}

.background-effect {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* right: 0; */
}

.background-effect-1 {
  position: absolute;
  top: 0;
  right: 0;
  /* left: 100%; */
  /* z-index: -1; */
}

.background-effect-mobile {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

/* Your additional styles for the content inside .bg_drop */

@media screen and (max-width: 768px) {
  .mrgin_top {
    margin-top: -5px;
  }
  .background-effect-mobile {
    top: auto;
  }
  h1 {
    font-size: 90px;
    line-height: 90px;
  }
  .gl-hero-body {
    font-size: 14px;
    text-align: center;
  }
  .btn_login {
    margin: 0 auto;
  }
  .planet {
    position: absolute;
    margin-top: 1px;
    margin-left: -10px;
  }
  .planets {
    margin-top: 0px;
    text-align: end;
  }
  .cta-btns {
    margin-left: 1rem;
    width: 267px;
    margin-bottom: 20px;
  }
  .gl-hero-lead-in {
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .mrgin_top_hero2{
    margin-top: 36px !important;
  }
  .img_res {
    width: 300.24px;
    height: 300.95px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .container-8-2 {
    font-size: 51px !important;
    text-align: center !important;
    line-height: 47.92px !important;
  }
  .gl-p {
    text-align: center !important;
    font-size: 14.5px !important;
    font-weight: 400 !important;
    line-height: 17.5px !important;
  }
  .left {
    margin-left: 0px !important;
  }
  h1 {
    font-size: 48px;
    line-height: 50px;
  }
  .container-8 {
    font-size: 44px !important;
    line-height: 45px !important;
    justify-content: center;
    text-align: center !important;
  }
  .planets {
    display: none !important;
  }
  .b-g {
    position: relative;
  }
  .cta-btn {
    margin-left: 0px !important;
  }
}
