h1 {
  margin: 0;
  padding: 0;
}

/* header section */
.headerContainer {
  transition: border-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
  border-bottom: 2px solid rgba(255, 255, 255, 0.299);
  border-bottom-width: 0.5px;
  backdrop-filter: none;
}

.headerContainer.scrolled {
  backdrop-filter: blur(10px);
}

.downloadAppBtn {
  background-color: #3d00cc;
}
.walletField {
  background-color: #383838;
}

.headerNavs {
  font-family: Montserrat;
}

.languageSelect {
  background-image: none !important;
  appearance: auto !important;
  padding: 0px 0px 0px 18px !important;
}

.form-select:focus {
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.app__navbar-smallscreen_links a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Footer Css */
.footer {
  background: linear-gradient(350.92deg, #0b0628 -0.61%, #222b78 190.25%);
  color: white;
  padding: 30px 0;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-logo img {
  width: 150px;
}

.app-links img {
  width: 120px;
  margin: 10px 5px;
}

.footer-bottom {
  /* background-color: #000000ad; */
  color: #aaa;
  padding: 0px 0;
}

.footContainer {
  padding-bottom: 0;
}

/* Phone Input */
.PhoneInput input {
  background-color: transparent;
}
.PhoneInput input:focus-visible {
  outline: none;
}
.cae-logo{
  height: 45px;
}
.bg_download{
  /* background-color: rgb(182, 36, 218);
   */
   background-color: rgb(241, 170, 52);
}
/* Media Query */
/* @media screen and (min-width: 768px) and (max-width: 991.98px){
  
} */

@media screen and (min-width: 768px) and (max-width: 992px) {

  .headerContainer {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px !important;
  }
  .profileSec {
    display: flex !important;
  }
  .languageSelect {
    font-size: 25px;
  }

  /* footer */
  .footTitle {
    /* padding-bottom: 10px !important; */
  }

  .copyRightSec {
    padding: 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px; */
  }
  .copyRightSec p {
    text-align: left;
    font-size: 18px;
  }
  .footContainer .container {
    padding: 0px 30px;
  }
  .footer-logo {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 22px;
  }
  .footPayment {
    display: flex;
    justify-content: flex-start !important;
  }
  .footerCol1 {
    /* width: 50%; */
  }
  .footerCol2 {
    /* width: 50%; */
  }
  .footerCol3 {
    width: 100%;
  }
  .footerCol4 {
    width: 100%;
  }
  .downloadAppSec {
    padding: 0 !important;
  }
}

@media screen and (max-width: 575px) {
  .mbl_cen{
    width: 65%;
  }
  .app-links img{
    margin: 0px;
  }
 
  .footer{
    padding: 10px;
  }
  .headerLogo img{
    height: 40px;
    margin-left: 47px;
  }
  .headerContainer {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px !important;
  }
  .profileSec {
    display: flex !important;
  }
  .languageSelect {
    font-size: 25px;
  }

  /* footer */
  .footTitle {
    /* padding-bottom: 10px !important; */
  }
  .copyRightSec {
    padding: 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .copyRightSec p {
    text-align: left;
    font-size: 13px;
  }
  .footContainer .container {
    padding: 0px 0px;
  }
  .footer-logo {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 22px;
  }
  .footPayment {
    display: flex;
    justify-content: flex-start !important;
  }
  .footerCol1 {
    /* width: 50%; */
  }
  .footerCol2 {
    /* width: 50%; */
  }
}

@media screen and (max-width: 380px) {
  .languageSelect {
    font-size: 18px;
  }
  .headerLogo img {
    width: 80px;
  }
  .profileSec img {
    width: 35px;
  }
  .burgurMenu {
    width: 30px;
  }
  .footTitle {
    font-size: 15px;
    padding-bottom: 3px !important;
  }
  .footer-logo img {
    width: 140px;
  }
  .copyRightSec p {
    font-size: 12px;
  }
}

