.margin {
    margin-top: 127px;
    /* margin-left: 1rem; */
    z-index: -1;
}



.lead-in {
    color: #cfcfcf;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 500;
    position: absolute;
    left: 4rem;
}

.gl-h2.gl-s2-headline, .gl-h2.gl-c-s2-headline {
    width: 100%;
    text-align: start;
}

.gl-h2 {
    color: #fad550;
    letter-spacing: -1.4px;
    margin-bottom: 16px;
    font-size: 60px;
    line-height: 80px;
}

h2 {
    letter-spacing: -.4px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
}
h2 {
    margin-top: 20px;
    font-size: 32px;
    line-height: 36px;
}


h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.gl-p {
    color: #cfcfcf;
    font-size: 20px;
    line-height: 32px;
    text-align: start;
}

p {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
}
p {
    margin-top: 0;
    margin-bottom: 10px;
}

.cta-btng {
    padding: 0.7rem 2.5rem;
    background: #fff;
    color: #060606;
    /* margin-left: 1rem; */
    margin-top: 2rem;
    z-index: -1;
    font-weight: bold;
    border-radius: 3%;
    border: none;
    outline: none;
}
.cta-btng:hover {
    background-color: #ebefff;
    color: #000;
}



.gl-number {
    letter-spacing: -1px;
    font-size: 72px;
    font-weight: 800;
    line-height: 80px;
    color: #fff;
}

.gl-section {
    color: #fff;
    text-align: center;
    flex-direction: column;
    align-items: center;
    
}


.gl-numbers {
    letter-spacing: -1px;
    font-size: 72px;
    font-weight: 800;
    line-height: 80px;
    color: #fff;
}

.gl-sections {
    color: #fff;
    text-align: center;
    flex-direction: column;
    align-items: center;
    /* padding: 56px; */

    
}
.num_counter{
    display: flex;
    flex-direction: column;
}
@media screen and (max-width:992px) {
    .num_counter{
        flex-direction: row;
    }
}
@media screen and (max-width:600px) {
    .gl-number,.gl-numbers{
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
    }
    p{
        font-size: 13px;
    }
    .expand-head{
        font-size: 26px !important;
    }
    h3{
        font-size: 23px;
    }
    .center{
        justify-content: center;
        text-align: center !important;
    }
    .gl-h2{
        font-size: 30px;
        line-height: 40px;
    }
    .margin {
        margin-top: 0px !important;
        /* margin-left: 1rem; */
        z-index: -1;
    }
}
.compete_img{
    width: 590px;
    height: 400px;
}