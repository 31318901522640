.card_trust {
    position: relative; /* Add relative positioning for pseudo-element */
    background-color: rgba(7, 7, 7, .5);
    backdrop-filter: blur(10px); /* Adjust the blur value as needed */
    border-radius: 18.4px; /* Rounded corners for the inner card */
    padding: 16px;
    height: 257px;
    background: #241E3445; /* Set the background color */
}

.card_trust::before {
    content: ''; /* Create a pseudo-element to draw the outer border */
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 18.4px !important; /* Rounded corners for the outer border */
    border: 0.8px solid transparent; /* Solid border with transparent color */
    border-image-source: linear-gradient(180deg, rgba(255, 17, 245, 0.97) 0%, rgba(253, 253, 255, 0.62) 100%); /* Apply gradient border */
    border-image-slice: 1;
    border-image-width: 1;
}







/* .card_trust2{
    background: rgba(36, 30, 52, 0.27);
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 8px;
    padding: 16px 16px 0;
    height: 150px;

} */
.card_trust2 {
    position: relative; 
    background-color: rgba(7, 7, 7, .5);
    backdrop-filter: blur(10px);
    border-radius: 18.4px; 
    padding: 16px;
    height: 150px;
    background: #241E3445;
}

/* .card_trust2::before {
    content: ''; 
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 18.4px !important; 
    border: 0.8px solid transparent; 
    border-image-source: linear-gradient(180deg, rgba(255, 17, 245, 0.97) 0%, rgba(253, 253, 255, 0.62) 100%); 
    border-image-slice: 1;
    border-image-width: 1;
} */


.testi{
    height: 47px;
    width: 60px;
    border-radius: 10px;
    margin-bottom: 22px;
}
.card_text{
    color: #a0a8bd;
    font-size: 14px;
    line-height: 16px;
}
.card_trust2{
    height: 176px;
}
@media screen and (max-width:600px) {
    .card_trust{
        height: auto;
    }
    .card_trust2 {
        height: 250px !important;
        padding: 1rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}