.card_cator {
  text-align: left;
  /* background-color: rgba(14,14,14,.95); */
  /* background: linear-gradient(
    129deg,
    rgba(61, 0, 204, 0.27) -64.93%,
    rgba(36, 68, 180, 0.27) 36.01%,
    rgba(89, 0, 80, 0.27) 136.94%
  ); */
  /* background: linear-gradient(rgba(61,0,204,.27),rgba(36,68,180,.27),rgba(89,0,80,.27)); */
  background: linear-gradient(rgba(61,0,204,.27),rgba(36,68,180,.27),rgba(89,0,80,.27));

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 64px 48px; */
  padding: 20px;
  display: grid;
  position: relative;
  height: 204px;
}
.border-video {
  border: 9px solid var(--Strokes, rgba(66, 60, 95, 1));
  /* margin-top: 200px; */
  margin-top: 150px;
 
}
.bg_video{
  background: url('../../assets/images/grow.png');
  background-repeat: no-repeat;
  background-size: contain;
  
}
.video-react-poster,
.rounded_video {
  border-radius: 40px; /* Adjust the border radius as needed */
}

/* .video-react .video-react-poster, .video-react{
  background-color: transparent !important;
  background-image: url('../../assets/images/video_preview.png') !important;
  background-position: center !important;
  background-size: cover !important;
} */
.video-react .video-react-control-bar {
  /* display: none; */
  width: 95% !important;
  position: absolute;
  bottom: 1px !important;
  left: 0;
  right: 0;
  height: 3em;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  margin: 0 auto !important;
}
@media (max-width: 768px) {
  /* Adjust the width and height for screens smaller than 768px */
  .mob_video {
    width: 100% !important;
    height: auto;
  }
}
.bor_video {
  border: 1px solid #fad550;
  border-radius: 10px;
}
.video-react .video-react-video {
  border-radius: 37px;
}
.rounded_vedio {
  border-radius: 47px;
}
.video-react .video-react-control-bar {
  border-radius: 37px;
  /* margin-bottom: 20px; */
}
.video-react .video-react-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 70px;
  width: 70px;
  display: block;
  position: absolute;
  top: 40% !important;
  left: 45%;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  /* border: 0.06666em solid #fff; */
  border: 1px solid transparent;
  background-color: #2b333f;
  /* background-color: rgba(43, 51, 63, 0.7); */
  background-color: #a3a7ab;
  border-radius: 50%;
  transition: all 0.4s;
}
.video-react-big-play-button:before {
  position: absolute;
  top: 11px !important;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 70px;
}
@media screen and (max-width: 600px) {
  /* .work-title{
    text-align: center;
  } */
  .video-react .video-react-control-bar{
    bottom: 0px !important;
  }
  .rounded_vedio {
    margin-top: 70px !important;
  }
  .card_cator {
    height: 100%;
    padding: 1rem !important;
  }
  .mob_fon2t {
    font-size: 38.66px !important;
    font-weight: 700 !important;
    line-height: 37.11px !important;
    letter-spacing: -0.02em !important;
    text-align: center !important;
}

  .video-react .video-react-big-play-button {
    top: 33% !important;
    left: 41% !important;
  }
}
