.work-title {
    color: white;
    /* letter-spacing: 2px; */
    /* text-transform: uppercase; */
    /* margin-bottom: 16px; */
    /* margin-top: 10px; */
    /* font-weight: 500; */

}
.bg_head{
    /* background-image: url("../../assets/work1.png"); */
}
.bg_team{

}
.expand-head{
    letter-spacing: -1.4px;
    margin-bottom: 16px;
    font-size: 46px;
    /* line-height: 80px; */
    color: #fad550;
}
.clr{
    color:  rgba(175, 139, 255, 1) !important;
}

.card{
    text-align: left;
    /* background-color: rgba(14,14,14,.95) !important; */
    /* background: linear-gradient(180deg, rgba(11, 94, 255, 0.27) 0%, rgba(34, 2, 163, 0.27) 49%, rgba(163, 47, 165, 0.27) 100%); */
    /* background: linear-gradient(180deg, rgba(11, 94, 255, 0.27) 0%, rgba(34, 2, 163, 0.27) 49%, rgba(163, 47, 165, 0.27) 100%); */
    background: linear-gradient(180deg, #15265b, #1a154a, #341845);

    border: 1px solid rgba(255,255,255,.1);
    border-radius: 18px;
    align-items: flex-start;
    /* padding: 64px 48px; 
    */
    padding: 17px;
    /* display: grid; */
    position: relative;
    /* margin-bottom: 50px; */
    /* margin-top: 20px; */
    height: 350px !important;
}

.gl-card-icon {
    width: 56px;
    height: 56px;
    object-fit: contain;
}

@media screen and (max-width: 992px) {
    .flex-wrap-responsive {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .mob_font{
        font-size: 31px !important;
    }
    .flex-wrap-responsive {
        flex-wrap: wrap;
    }
    .card{
        height: auto !important;
    }
}