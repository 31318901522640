.text-white {
    color: #fff;
}

/* .center {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
} */

.navbar-nav .dropdown-menu {

    background-color: rgb(26, 26, 26);

}
.btn_login{
    /* background-color: #fad550 !important; */
    background: linear-gradient(90deg, #6413B6 100%, #2E005B 0%);
    border: 0;
    color: white !important;
    text-decoration: none;
    outline: 0;
    padding: 10px 30px 10px 30px !important;
    font-weight: 600 !important;
}
.btn:hover{
    opacity: 0.65;
}
.gap{
    gap: 30px;
}
.bor_nav{
    border:  0.91px solid rgba(95, 86, 136, 1);
    border-radius: 16.44px;
    margin-top: 20px;
}
.dropdown-item {
    color: #fff;
}

.btn-dark {
    background: #fff;
    color: #060606;
}

.btn-dark:hover {
    background-color: #ebefff;
    color: #000;
}

.navbar-nav .nav-link,
.navbar-nav .nav-link.show {
    color: #fff;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #fff;
}


.sticky-header {
    /* background-color: rgba(17, 15, 15, 1); */
    /* -webkit-backdrop-filter: blur(8px);   */
    backdrop-filter: blur(12px);
    /* transform: translateX(-50%); */
    transition: all .7s ease-out;
    /* position: sticky; */
    /* opacity: 1; */
    background: hsla(0,0%,100%,.02);
/* border-bottom: 1px solid rgba(255,255,255,.1); */
/* display: none; */
/* position: absolute; */
/* top: 0%;
bottom: 0%;
left: 0%;
right: 0%; */
    /* transform: translateY(-80px); */
    z-index: 99999;
    /* animation: sticky-header 0.3s ease forwards; */
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: white !important;
}
.navbar-light .navbar-nav .nav-link {
    color: white !important;
}
.logo{
    width: 155px;
    height: 77px;
    margin-left: 22px;
}
@keyframes sticky-header {
    0% {
        transform: translateY(-80%);
    }

    100% {
        transform: translateY(0);
    }
}               
.dropdown-item{
    color: white !important;
}
.dropdown-item:hover{
    color: black !important;
}
.navbar-light .navbar-toggler-icon{
    background-image: url("../../assets/images/navtig.png") !important;
}
.mrgin_new{
    margin-top: 180px;
}



/* .app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    transition: 0.5s ease;
  
    flex-direction: column;
    z-index: 5;
  } */
  
  .app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 100000000;
    backdrop-filter: blur(8px); /* Add blur effect */
    -webkit-backdrop-filter: blur(8px); /* For Safari */
}

  .app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: #ffff;
    cursor: pointer;
  
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .app__navbar-smallscreen_links {
    list-style: none;
    color: #fff;
    /* font-size: 20px; */
    height: 100vh;
    overflow-y: scroll;
  }
  
  .app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-size: 2rem;
    text-align: center;
    /* font-family: var(--font-base); */
  }
  
  .app__navbar-smallscreen_links a {
    text-decoration: none;
    font-size: 18px;
  }
@media screen and (max-width:992px) {
    .logo {
        width: 78px;
        height: 46px;
        margin-left: 14px;
    }
    .bor_nav{
        margin-top: 0px;
        padding: 4px;
        border: none;
    }
     .navbar-nav{
        background-color: black !important;
     }
     .res_nav{
        display: none !important;
     }
     .mrgin{
        margin-top: 100px !important;
     }
     .mrgin_new{
        margin: 18px;
     }
    }