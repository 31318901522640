p {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

button {
  font-family: Montserrat;
}

.competeInfoCon .modal-dialog {
  max-width: 45% !important;
}

.competeInfoCon .modal-content {
  /* min-height: 222px; */
}

.competetionInfoBtns:hover {
  box-shadow: 0 0 0.4rem rgba(255, 255, 255, 0.25);
  opacity: unset !important;
}

.competetionInfoBtns{
  border-radius: 12px;
}

@media screen and (min-width: 768px) and (max-width: 1180px) {
  .competeInfoCon .modal-dialog {
    max-width: 100% !important;
  }
  .competetionInfoBtns {
    font-size: 12px;
  }
  .competeInfoTitle {
    font-size: 13px !important;
  }
  .walletAmountValue {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 575px) {
  .competeInfoCon .modal-dialog {
    max-width: 100% !important;
  }
  .competetionInfoBtns {
    font-size: 12px;
  }
  .competeInfoTitle {
    font-size: 16px !important;
  }
  .walletAmountValue {
    font-size: 13px !important;
  }
}
