.textPrimary {
  color: #5668ff;
}

.textSecondary {
  color: #3ea2ff;
}

.lightGreen {
  color: #c9ffc0;
}
