.pos {
  position: relative;
}

.planet_img {
  width: 5.89vw;
  height: 5.89vw;
  max-height: 104px;
  max-width: 104px;
  object-fit: contain;
  position: absolute;
  top: 3.53vw;
  left: 5.5vw;
}
.compete_img {
  height: 500px;
  width: 100%;
  border-radius: 20px;
}

.glows-background {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  z-index: -1;
}

.glows-background-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
