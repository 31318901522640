p {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

button {
  font-family: Montserrat;
}

.competSection-btn {
  width: 107px;
  margin-top: 10px;
}

.eventDetailsContainer {
  padding-top: 2rem;
}

.eventStarCount {
  font-size: 19.54px;
}

.eventState {
  font-size: 26.87px;
}

.editionPhotos {
  padding: 0 8px;
}

.editionPhotos img {
  width: 100%;
  height: auto;
}

.editionColPhotos img {
  width: 200px;
}

/* Experience Host */
.experienceHost {
  font-size: 26px;
}

.hostTime {
  font-size: 18px;
  line-height: 33.97px;
}

.hostProfileCol1 img {
  border-radius: 20px;
}

.ratedHost h2 {
  font-weight: 500;
  line-height: 33.97px;
}

.ratedHost p {
  font-size: 16px;
  font-weight: 300;
  line-height: 19.1px;
}
.whatYouDo h1 {
  line-height: 40px;
  padding-bottom: 10px;
}

.whatYouDo p {
  font-size: 18px;
  line-height: 22.88px;
  max-width: 700px;
}

.eventPriceCard {
  background-color: transparent;
  border: 1px solid #3ea2ff6d;
  border-radius: 20px;
  padding: 39px 38px;
}

.eventPriceFrom h1 {
  font-size: 20.69px;
  line-height: 28.87px;
}

.eventPriceFrom span {
  font-size: 20px;
}

.eventPriceFrom p {
  font-size: 16.78px;
  line-height: 20.45px;
  text-decoration: underline;
}

.selecteEventDate {
  border: 1px solid #3ea2ff6d;
  border-radius: 20px;
}

.selecteDateCol {
  padding: 20px 25px;
}

.eventTimeSec h1 {
  font-size: 14.78px;
  line-height: 21.67px;
}
.eventTimeSec h3 {
  font-size: 16.78px;
  line-height: 20.45px;
  color: white;
}

.eventTimeSec p {
  font-size: 13.78px;
  line-height: 15.58px;
}

.chooseDate {
  width: 80%;
  padding: 5px 12px;
  background-color: #3ea2ff;
  border-radius: 7px;
}

/* Include Section */
.includedCardSec {
  padding: 30px 22px;
}
.includedCard {
  min-width: 210px;
  background-color: transparent;
  border: 1px solid #0b6fcc;
  border-radius: 10px;
  padding: 18px 22px;
}

.featureDetailsBtn {
  min-width: 20%;
  padding: 10px 50px;
  border: 1px solid #f0eded;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

/* Meet Host */
.meetHostDesc {
  font-size: 18px;
  line-height: 29.1px;
}

.meet-host-image {
  height: 70px;
  width: 70px;
  border-radius: 100px !important;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  -ms-border-radius: 100px !important;
  -o-border-radius: 100px !important;
}

.contactHostBtnSec {
  /* margin-left: 7%; */
}

.contactHostBtn {
  /*max-width: 40%;
  */
  width: 20%;
  padding: 10px 50px;
  border: 1px solid #f0eded;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}


/* Thinks To Know */
.guestRequirements p {
  font-size: 20px;
  line-height: 29.1px;
}

.includeCardPara {
  font-size: 14px !important;
  line-height: 22px;
}
.bg-event-price{
  /* background: rgba(0, 0, 0, 0.6); */

  /* rgb(82 16 98) */
   /* background: rgb(182, 36, 218);
    transition: 0.5s ease;
    backdrop-filter: blur(8px); */
    /* height: 10vh; */
    background: rgba(182, 36, 218, 0.5); 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
    transition: 0.5s ease;
}

.scroll-padding {
  margin-top: 100px; 
}

.zindex_img{
  /* z-index: -1 !important; */
}

.glassy {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 8px; /* Rounded corners for a smooth glassy look */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: subtle border for more depth */
}

/* Similar experiences */
@media screen and (min-width: 768px) and (max-width: 1180px) {
  .hostProfileCol2 {
    width: 90%;
  }
  .contactHostBtn {
    width: 40%;
  }
  .featureDetailsBtn {
    width: 40%;
  }
  .hostedBySec {
    display: flex;
    flex-direction: column !important;
    gap: 20px;
  }
  .hostByCol1 {
    width: 100%;
  }
  .hostByCol2 {
    width: 100%;
  }

  .hostTime {
    font-size: 22.87px;
  }
}
@media screen and (max-width: 575px) {
  .mobile_size{
    line-height: 25.19px;
        font-size: 20.66px;
  }
  .bottomHrLine_margin{
    margin: -5px !important;
  }
  .eventsTopSec {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .container-9 {
    width: 100% !important;
    max-width: 100%;
  }

  .similarExpTitle {
    font-size: 24px;
  }

  .includedCard {
    padding: 4px;
  }
  .eventGalleryTitle {
    font-size: 22.87px;
    line-height: 27.88px;
  }
  .eventStarCount {
    font-size: 12.33px;
  }
  .eventState {
    font-size: 14.21px;
  }
  .editionPhotosSection {
    display: none;
  }
  .hostedBySec {
    display: flex;
    flex-direction: column !important;
    gap: 20px;
  }
  .hostByCol1 {
    width: 100%;
  }
  .hostByCol2 {
    width: 100%;
  }
  .experienceHost {
    line-height: 18.11px;
    font-size: 14.85px;
  }
  .hostTime {
    font-size: 13.86px;
    line-height: 16.89px;
  }
  .ratedHost h3 {
    font-size: 15.01px;
    font-weight: 500;
    line-height: 18.3px;
  }
  .ratedHost p {
    font-size: 12.86px;
    font-weight: 300;
    line-height: 15.68px;
  }
  .guestSaySec img {
    width: 22px;
  }
  .whatYouDo h1 {
    line-height: 25.19px;
    font-size: 20.66px;
  }
  .whatYouDo {
    /* padding-top: 15px !important; */
  }
  .whatYouDo p {
    font-size: 12.05px;
    line-height: 20.25px;
  }
  .eventPriceFrom h1 {
    font-size: 21.69px;
    line-height: 30.87px;
  }
  .chooseDate {
    font-size: 18px !important;
  }
  .whatsIncludeSec {
    display: flex;
    flex-direction: column !important;
    gap: 20px;
  }
  .whatsIncludeCol1 {
    width: 100%;
  }
  .whatsIncludeCol2 {
    width: 100%;
  }
  .includedCard {
    min-width: 135px;
    width: 45%;
  }
  .includedCard2{
    min-width: 157px !important;
    width: 45%;
    min-height: 146px;
  }
  .includedCardSec {
    padding: 30px 0px;
    padding-top: 20px !important;
  }
  .includeCardTitle {
    font-size: 12px !important;
    line-height: normal;
  }
  .whatIncludeHeading {
    font-size: 19.25px;
    line-height: 23.47px;
  }
  .includeCardPara {
    font-size: 12px !important;
    line-height: 18px;
  }
  .whatsIncTopSec {
    padding-top: 15px !important;
    padding-bottom: 0px !important;
  }
  .accessibilSec h1 {
    font-size: 22.58px;
    line-height: 27.53px;
  }
  .accessibilSec p {
    font-size: 15.91px;
    line-height: 19.4px;
    padding-top: 5px !important;
  }
  .featureDetailsBtn {
    width: 100%;
    font-size: 18px !important;
  }
  .accessibilSec {
    padding-bottom: 0px !important;
  }
  .yourHostTitle {
    font-size: 16.99px;
    line-height: 20.71px;
  }
  .hostProfile img {
    object-fit: cover !important;
  }
  .hostProfileCol1 {
    width: 10%;
  }
  .hostProfileCol2 {
    width: 90%;
    gap: 10px !important;
  }
  .meetHostDesc {
    font-size: 10.05px;
    line-height: 12.25px;
  }
  .reviewSec h3 {
    font-size: 10.05px;
    line-height: 12.25px;
  }
  .reviewSec img {
    width: 18px;
  }
  .contactHostBtnSec {
    margin-left: 0%;
    padding: 0px 10px;
  }
  .contactHostBtn {
    width: 100%;
    font-size: 18px !important;
  }
  .meetHostTopSec {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .thinksToTopSec {
    /* padding-top: 15px !important; */
    padding-bottom: 15px !important;
  }
  .thinksToTitle {
    font-size: 27.07px;
    line-height: 33px;
  }
  .guestReqSec {
    display: flex;
    flex-direction: row;
  }
  .guestReqCol {
    width: 100%;
  }
  .guestReqCol h3 {
    font-size: 19.45px;
    line-height: 23.72px;
  }
  .guestRequirements p {
    font-size: 12.52px;
    line-height: 15.26px;
  }
  .healthSaftyText {
    padding-top: 20px !important;
  }
  .guestReqCol img {
    width: 50px;
  }
  .guestReqColSec {
    display: flex;
    flex-direction: column !important;
    gap: 15px !important;
  }
}
@media screen and (max-width: 380px) {
}
