
.bg_moon {
    background-image: url(../../assets/Rings-2.webp);
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: cover; */
    z-index: 1;
    /* margin-top: 10rem; */
}
.large {
    transform: scale(3);
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 30%;
    z-index: -1;
    overflow: hidden;

}

.small {
    z-index: -1;
    opacity: 1;
    /* mix-blend-mode: overlay;
    background-image: url(../../assets/Noise\ Tile.webp); */
    background-position: 0 0;
    background-size: 400px;
    display: block;
    position: absolute;
    top: 120%;
    bottom: 0%;
    left: 35%;
    right: 0%;
    width: 300px;
    height: 300px;
    border-radius: 20px;
}




.gl-s1-planet-edge {
    width: 98vw;
    /* height: 15.39vw; */
    max-height: 266px;
    position: absolute;
    top: 1148px;
    left: 0%;
    right: 0%;
    z-index: -1;
}

.gl-s1-planet {
    width: 15vw;
    height: 15vw;
    position: absolute;
    top: 1088px;
    opacity: 0.8;
    right: 9vw;
}
@media screen and (max-width: 600px) {
    .small_med{
        width: 200px;
        height: 200px;
        z-index: -1;
        opacity: 1;
        background-position: 0 0;
        /* background-size: 400px; */
        display: block;
        position: absolute;
        top: -50px;
        bottom: 0%;
        left: 0px;
        right: 0%;
        
     }
     .gl-s1-planet-edge{
        display: none;
     }
}
@media screen and (max-width: 500px) {
    .small_med{
        width: 200px;
        height: 200px;
        z-index: -1;
        opacity: 1;
        background-position: 0 0;
        /* background-size: 400px; */
        display: block;
        position: absolute;
        top: -50px;
        bottom: 0%;
        left: -9% !important;
        right: 0%;
        
     }
     .large_med{
        /* margin-top: 40px; */
     }  
     .large {
        transform: scale(3);
        position: relative;
        width: 100px;
        height: 101px;
        border-radius: 30%;
        z-index: -1;
        overflow: hidden;
    }
}