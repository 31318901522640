p {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

button {
  font-family: Montserrat;
}

.loginContainer .modal-content {
  /* background-color: #1f1f1f !important; */
  /* background: linear-gradient(180deg, #7272728c 31.25%, rgb(49 8 93) 114.58%); */
  background: linear-gradient(180deg, #35393fe3 31.25%, rgb(49 8 93) 114.58%);
}
.loginContainer .modal-body {
  /* padding: 20px 40px !important; */
}
.popupLabels {
  font-size: 14px;
}
.loginContainer input {
  background-color: #ffe5e591;
  border: none;
  color: white;
}
.loginContainer input::placeholder {
  color: white;
}
.loginContainer .form-control:focus {
  color: #ffffff;
  background-color: #ffe5e591;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.25);
}
.popUpLogBtn {
  border: none;
  background: rgb(241, 170, 52);
  /* linear-gradient(
    118deg,
    #660e93d6 31.25%,
    rgb(217 25 229) 114.58%
  ); */
}

.popUpLogBtn2{
  border: none;
  background: rgb(182, 36, 218);
}
.popUpLogBtn:hover {
  box-shadow: 0 0 0.4rem rgba(255, 255, 255, 0.25);
}

.TandCText {
  color: #fad550;
  font-size: 15px;
  font-weight: 600;
}

/* User Details */
.loginContainer .userDetailsContainer .modal-content {
  background-color: #1f1f1f !important;
}

.modal-header .btn-close {
  filter: invert(1);
}

.modal-header .btn-close:focus {
  box-shadow: none !important;
}
