.pace{
    position: relative;
}

.bg_planet{
    width: 50.92vw;
    height: 45.92vw;
    margin: 0 auto;
}

.main{
    z-index: 111;
    width: 28.92vw;
    height: 28.92vw;
    max-height: 500px;
    max-width: 500px;
    object-fit: contain;
    display: block;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
}

@media screen and (max-width: 768px) {
    .responsive-margin-find {
        margin: 0px;
        margin-bottom: 20px;
    }
}