@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Inter'; */
}
body {
  /* background-image: url(../src/assets/back-ground.jpeg); */
  background-color: #181421;
}
.bg_header {
  background-color: rgba(14, 14, 14, 0.95) !important;
}
.accordion {
  /* --bs-accordion-border-radius: 0px !important; */
}
.accordion-button {
  background-color: #181422 !important;
  color: white !important;
  font-weight: 500;
  /* line-height: 43.96px; */
  font-size: 18.32px;
  /* padding: 20px; */
  /* margin: 10px; */
  /* border-bottom: 1px solid #fad550 !important; */
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.bor_faq {
  border: 2.01px solid rgba(66, 60, 95, 1);
  /* border-radius: 25.18px; */
}
.underlines a {
  text-decoration: none;
}
.accordion-button:focus {
  box-shadow: none !important;
  /* border-bottom: 1px solid #fad550 !important; */
}
.accordion-item {
  /* border: none !important; */
  /* border: 1px solid #fad550 !important; */
  /* border: 2px solid rgba(66, 60, 95, 1) !important; */
  border: none !important;
  /* border-radius: 2px !important; */
  background-color: transparent;
}

.accordion-button:after {
  background-image: url("../src/assets/images/+.png") !important;
}
.accordion-button:not(.collapsed):after {
  background-image: url("../src/assets/images/+.png") !important;
}
.accordion-body {
  background-color: black !important;
  color: white !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px;
  font-weight: 700;
}
h3 {
  letter-spacing: -0.2px;
  margin-top: 0;
  margin-bottom: 8px;
  font-family: Centra No\.2, sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 40px;
  color: #af8bff;
}
h4 {
  font-size: 20px;
  /* line-height: 24px; */
  color: white;
}
.container-8-2 {
  font-size: 80px;
  font-weight: 900;
  /* line-height: 112px; */
  text-align: start;
  background: linear-gradient(
    180deg,
    #954adf 31.25%,
    rgba(152, 58, 247, 0.36) 114.58%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.index {
  z-index: 999999;
}

/* Event Page */
.container-9 {
  width: 93% !important;
  max-width: 100%;
}

.eventBG {
  background-color: #08183b;
}

.eventBG * {
  font-family: Montserrat;
}

.bottomHrLine {
  border: 0.2px solid rgba(206, 206, 206, 0.381);
  margin: 21px 0;
}
.verticalLine {
  border: 0.005px solid #3ea2ff6d;
}

/* Font-size */
.fs-13 {
  font-size: 13px;
}

.fs-33 {
  font-size: 33.87px;
}

.fs-29 {
  font-size: 29.87px;
}

/* font-weight */
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

/* width */
.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media screen and (max-width: 575px) {
  .bottomHrLine {
    width: 100%;
  }
  h3{
    line-height: 27px;
  }
}
