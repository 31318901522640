.logo_trusted{
    width: 20px;
    height: 20px;
}
.box{
    cursor: pointer;
    /* border: 1px solid white; */
    padding: 10px;
    /* width: 100px; */
}
.mrgin{
    margin-top: 140px;
}
.bg_cards{
    background-image: url('../../assets/backgrounds/Group\ 23.png');


}
.bg_img{
    background-image: url('../../assets/trusted.webp');
    background-position: center;
    object-fit: cover;
    background-size: contain;
}