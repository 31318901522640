p {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

h1 {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

button {
  font-family: Montserrat;
}

.AELiveTitle {
  font-size: 41.62px;
  font-weight: 700;
  font-family: Montserrat;
  margin-bottom: 0;
}

.earnText {
  color: #5668ff !important;
  font-size: 30px;
  font-weight: 400;
}

.gap-55 {
  gap: 55px;
}

.nextCompetText {
  font-size: 19px;
}

.nextDescText {
  /* font-size: 20px; */
  font-size: 17px;
  font-weight: 500;
  max-width: 1250px;
}

.ticketFrom {
  font-size: 17px;
}

.eventSearch {
  width: 100%;
  font-size: 21.39px;
  outline: none;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #87b7ff;
  padding: 12px 12px 12px 70px !important;
}

.eventSearchInput {
  width: 40%;
}

.nextBackground-layer {
  position: absolute;
  top: 470px;
  left: 0;
  height: 410px;
  width: 100%;
  background-image: url("../../../assets/icons/nextCompetGradientBG.svg");
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.nextEventSec {
  position: relative;
  z-index: 1;
}
.nextCompetSection {
  background-image: url("../../../assets/backgrounds/nextCompetionBG.svg");
  background-size: cover;
  background-position: center;
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.joinInBtn {
  background-color: #5668ff;
  width: 150px;
  padding: 8px;
  border-radius: 20px;
  font-weight: 700;
}

.fastToComplete {
  /* font-size: 43.94px; */
  font-size: 31.94px;
  font-weight: 600;
  line-height: 53.57px;
  text-shadow: 0px 4.83px 4.83px #0000008c;
}

.fastToCompleteInCard {
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  text-shadow: 0px 4.83px 4.83px #0000008c;
}

.enjoyNewYork {
  font-size: 19.62px;
  font-weight: 500;
  line-height: 23.92px;
  /* width: 600px; */
}

/* Event Create Section */
.eventTitle {
  font-size: 24px;
}
.eventCardRow {
  padding: 0px 35px;
  display: flex;
  row-gap: 30px;
}

.eventCardSection {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* .eventCompetText {
  font-size: 24px;
} */

.eventDescText {
  font-size: 20px;
  font-weight: 500;
  line-height: 29.34px;
}

.eventTicketFrom {
  font-size: 24px;
  font-weight: 700;
}

.eventInclude {
  font-size: 18px;
  font-weight: 500;
}

.eventJoinInBtn {
  width: 100%;
  padding: 8px;
  border-radius: 20px;
  font-weight: 700;
}

.eventCardCon {
  background-image: url("../../../assets/icons/eventCarGradientBG.svg");
  background-position: center;
  border-radius: 20px;
}

/* Slider */
.carousel-inner-content {
  display: flex;
  justify-content: space-between;
}

.carousel-content {
  /* background-color: #FFEE56; */
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 0 10px;
  flex: 1;
  font-family: Montserrat;
}
.carousel-indicators {
  display: none !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  z-index: 100;
  padding: 30px;
  justify-content: space-between;
  height: 100%;
}
.aling_cards{
  gap: 25px;
  padding-top: 25px;
}
/* Media Query */
@media screen and (min-width: 768px) and (max-width: 1180px) {
  .eventSearchInput {
    width: 80%;
  }
  .eventCardCon {
    margin-top: 0px;
  }
  .eventSearch {
    width: 100%;
    font-size: 25px;
    padding: 15px 15px 15px 70px !important;
  }
  .eventSearchInput img {
    top: 22px !important;
    left: 30px !important;
  }
  .AELiveTitle {
    font-size: 50px;
  }
  .earnText {
    font-size: 22px;
  }
  .eventTitle {
    font-size: 20px;
  }
  .nextEventSec {
    /* display: none; */
  }
  .eventCardInner {
    width: 100%;
  }
  .eventCardSection {
    height: 542.14px;
  }
  .eventCardRow {
    padding: 0px;
  }
  .fastToCompleteInCard {
    font-size: 30px;
    line-height: 32.52px;
  }
  .eventDescText {
    font-size: 18px;
    line-height: 20.09px;
  }
  .eventTicketFrom {
    font-size: 18px;
  }
  .eventInclude {
    font-size: 16px;
  }
  /* .eventCompetText {
    font-size: 13px;
  } */
  .eventJoinInsec {
    gap: 15px !important;
  }
  .eventToComplete {
    gap: 7px !important;
  }
  .enjoyNewYork {
    font-size: 20px;
    line-height: 22.77px;
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .joinInBtn {
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .aling_cards{
    gap: 15px;
    padding-top: 15px;
  }
  .mrgin_top_hero{
    margin-top: 6px;
  }
  .nextCompetSection {
    gap: 12px;
    padding: 0px 30px 10px 30px;
  }
  .eventSearchInput {
    width: 100%;
  }
  .eventCardCon {
    margin-top: 0px;
  }
  .eventSearch {
    width: 100%;
    font-size: 17px;
    padding: 15px 15px 15px 50px !important;
  }
  .eventSearchInput img {
    top: 19px !important;
    left: 19px !important;
  }
  .AELiveTitle {
    font-size: 24px;
  }
  .earnText {
    font-size: 19px;
  }
  .eventTitle {
    font-size: 17px;
    padding-top: 3px;
    padding: 0px 24px;
  }

  .eventCardInner {
    width: 100%;
  }
  .eventCardSection {
    height: 342.14px;
  }
  .eventCardRow {
    padding: 0px;
  }
  .fastToCompleteInCard {
    font-size: 26px;
    line-height: 32.52px;
  }
  .eventDescText {
    font-size: 14px;
    line-height: 17.09px;
  }
  .eventTicketFrom {
    font-size: 15px;
  }
  .eventInclude {
    font-size: 14px;
  }
  .eventCompetText {
    font-size: 16px;
  }
  .eventJoinInsec {
    gap: 15px !important;
  }
  .eventToComplete {
    gap: 7px !important;
  }
  .enjoyNewYork {
    font-size: 13px;
    line-height: 15.77px;
    /* width: 386px !important; */
  }
  .carousel-control-next {
    right: -13px;
  }
  .carousel-control-prev {
    left: -13px;
  }
  .addressTitle1 {
    font-size: 15px;
  }
  .addressTitle2,
  .addressTitle3 {
    font-size: 13px;
  }
  /* Next Event Section */
  .nextEventSec {
    /* display: none; */
    margin-bottom: 30px;
  }
  .nextCompetText {
    font-size: 14px;
  }
  .nextCompetTitle {
    padding-left: 0px !important;
  }
  .fastToComplete {
    font-size: 30px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
  }
  .nextDescText {
    font-weight: 400;
    font-size: 15px;
    max-width: 745px;
    line-height: 23px;
  }
  .nextEventJoinSec {
    display: flex;
    flex-direction: column !important;
    gap: 5px;
    padding: 0 !important;
  }
  .ticketFrom {
    font-size: 15px;
  }
  .nextExcitingSec {
    gap: 2px;
  }
}

@media screen and (max-width: 380px) {
  .mrgin_top_hero {
    margin-top: 30px;
  }
  .languageSelect {
    font-size: 16px;
  }
  .eventSearchInput {
    width: 90%;
  }

  .enjoyNewYork {
    font-size: 13px;
    width: 306px !important;
  }
  .earnText {
    font-size: 14px;
  }
  .eventTitle {
    font-size: 14px;
    padding-top: 30px !important;
  }
  .fastToCompleteInCard {
    font-size: 22px;
  }
  .eventDescText {
    font-size: 12px;
  }
  .eventJoinInBtn {
    font-size: 14px;
  }
}
