.work-container {
  margin-top: 12em;
}

.work-bg {
  position: relative;
}

.gl-s2-gradient {
  z-index: -1;
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: contain;
  object-position: 50% 20%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}

.left {
  margin-left: -170px;
  z-index: -1;
  position: relative;
  mix-blend-mode: screen; /* Apply the "Screen" blend mode */
}

.gl-s2-profile {
  width: 250px;
  max-height: auto;
  max-width: 250px;
  height: 502px;
  margin-top: 73px;
}

.gl-s2-prefs {
  width: 17vw;
  height: 29vw;
  position: absolute;
  text-align: end;
  right: 0px;
  bottom: 49px;
  top: 4px;
}

.gl-s2-portfolio {
  /* position: absolute;
    width: 17vw;
    height: 29.58vw;
    top: 0px;
    
    z-index: -1; */
  position: absolute;
  height: 580px;
  top: 0px;
  z-index: -1;
  width: 479px;
  left: -217px;
}

.lead-out {
  color: #cfcfcf;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-weight: 500;
}

.cta-btnh {
  padding: 0.7rem 2.5rem;
  background: #fff;
  color: #060606;
  margin-left: 1rem;
  margin-top: 2rem;
  font-weight: bold;
  border-radius: 3%;
  border: none;
  outline: none;
}
.cta-btnh:hover {
  background-color: #ebefff;
  color: #000;
}

.mobile-background {
  position: absolute;
  right: 0;
}
.mobile-background-check {
  position: absolute;
  /* right: 0; */
  z-index: -1;
  justify-content: center;
  display: flex;
  text-align: center;
  left: -19%;
}

@media screen and (max-width: 992px) {
  .size_mob {
    width: 198.98px;
    height: 400px;
    gap: 0px;
    opacity: 0px;
  }

  .gl-s2-gradient {
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: contain;
    object-position: 50% 20%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .gl-s2-profile {
    width: 150px;
    height: auto;
    max-height: 504px;
    max-width: 300px;
  }

  .gl-s2-prefs {
    width: 97px;
    height: auto;
    position: absolute;
    text-align: end;
    right: 12%;
    bottom: 49px;
  }

  .gl-s2-portfolio {
    position: absolute;
    width: 93px;
    height: auto;
    max-height: 356px;
    max-width: 272px;
    top: 20px;
    z-index: -1;
    left: 4%;
  }
  .work-container {
    margin-top: 3em !important;
  }
}
