.similarExpTopSecEvent {
  width: 100%;
}

/* Navigation Design */
.similarExpTopSecEvent .swiper-button-next-custom,
.similarExpTopSecEvent .swiper-button-prev-custom {
  background-color: #3ea2ff !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  top: -2% !important;
  transform: translateY(-50%) !important;
  z-index: 1 !important;
  cursor: pointer !important;
}

.similarExpTopSecEvent .swiper-button-next-custom::after,
.similarExpTopSecEvent .swiper-button-prev-custom::after {
  color: #fff;
  font-size: 20px;
}

.similarExpTopSecEvent .swiper-button-next-custom {
  right: 20px;
}

.similarExpTopSecEvent .swiper-button-prev-custom {
  left: 91%;
}

/* Similar experiences */
@media screen and (min-width: 768px) and (max-width: 1180px) {
  .guestReqColSec {
    width: 90% !important;
  }
}
@media screen and (max-width: 575px) {
  /* Center only the swiper with the class "centered-swiper" */
.centered-swiper .swiper-wrapper {
  justify-content: center;
}

  .similarExpTopSecEvent {
    padding-top: 25px !important;
    padding-bottom: 15px !important;
  }
  .similarExpTitle {
    padding-bottom: 10px !important;
  }
  .similarExpPriceSec {
    width: 350px;
  }
  .similarExpSlideImg {
    width: 100%;
  }

  /* Mobile Slider */
  .mobileSliderCon {
    margin-top: -22px;
  }

  /* Navigation Design */
  .similarExpTopSecEvent .swiper-button-next-custom,
  .similarExpTopSecEvent .swiper-button-prev-custom {
    width: 35px;
    height: 35px;
    top: 20%;
  }
  .similarExpTopSecEvent .swiper-button-next-custom {
    right: 30px;
  }

  .similarExpTopSecEvent .swiper-button-prev-custom {
    left: 65%;
  }

  /* Navigation Design */
  .swiper-button-next-custom,
  .swiper-button-prev-custom {
    background-color: #3ea2ff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
  }

  .swiper-button-next-custom::after,
  .swiper-button-prev-custom::after {
    color: #fff;
    font-size: 20px;
  }

  .swiper-button-next-custom {
    right: 20px;
  }

  .swiper-button-prev-custom {
    left: 75%;
  }
}
