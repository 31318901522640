p {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

button {
  font-family: Montserrat;
}

.thanksTitle {
  font-size: 65px;
  line-height: 98.89px;
  font-weight: 700;
}
.competNameSection {
  /* background-image: url("../../../assets/images/confirmationBg.png"); */
  background-position: center;
  /* height: 428px; */
  background-size: cover;
  background-position: center;
  padding: 18px 30px;
  border-radius: 15px;
  flex-direction: column;
}

.competNameSection p {
  font-size: 40.12px;
  line-height: 53.78px;
}
.event_pad{
  /* padding: 80px; */
  height: 250px;
}

.itsOfficial h1 {
  font-size: 34px;
  font-weight: 700;
  line-height: 38.89px;
}

.itsOfficial p {
  font-size: 18px;
  font-weight: 400;
  /* line-height: 22px; */
  /* width: 60%; */
}
.img_wid{
  width: 300px;
  height: 440px;
}
.gold{
  color: rgb(241, 170, 52) !important;
  font-size: 18px;
}

.competInfoTitle {
  font-size: 54.44px;
  line-height: 66.36px;
  font-weight: 600;
}

.competeInfoSec h3 {
  font-size: 20.44px;
  /* line-height: 51.73px; */
  font-weight: 600;
}

.competeInfoSec p {
  font-size: 20px;
  /* line-height: 41.45px; */
  font-weight: 400;
  color: #fffbfb;
}

.appDownloadBtn {
  min-width: 33%;
  padding: 10px 10px;
  background-color: rgb(241, 170, 52);
  border-radius: 19px;
}
.loginContainer{
  backdrop-filter: blur(3px);
}
.yes_check{
  border-radius: 20px;
}
.popUpLogBtn.selected {
  background-color: #b624da;
  color: white; 
  border-radius: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1180px) {
  .eventDetailsContainer {
    padding-top: 0px;
  }
  .thanksTitle {
    font-size: 38.12px;
    line-height: 20.89px;
    padding-bottom: 35px !important;
  }
  .competNameSection p {
    font-size: 25.12px;
    line-height: 35.78px;
  }
  .itsOfficialSec {
    /* padding: 40px 20px !important; */
  }
  .itsOfficial h1 {
    font-size: 30px;
    line-height: 32.89px;
  }
  .itsOfficial p {
    font-size: 18px;
    line-height: 22.45px;
  }
  .competInfoCol1 {
    width: 10%;
  }
  .competInfoCol1 img {
    width: 100%;
  }
  .competInfoCol2 {
    width: 75% !important;
  }
  .competInfoTitle {
    font-size: 25.44px;
    line-height: 22.36px;
  }
  .competeInfoSec h3 {
    font-size: 22.44px;
    line-height: 38.73px;
  }
  .competeInfoSec p {
    font-size: 18px;
    line-height: 25.45px;
  }
  .appDownloadBtn {
    width: 100%;
    font-size: 18px !important;
  }
  .otherCompetSec {
    padding-top: 10px !important;
  }
  .ifNoOthertitle {
    padding-top: 20px;
    font-size: 22px;
    line-height: 32.73px;
  }
  .confirmPaymentSec img {
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  .team_mem{
    width: 77%;
  }
  
  .eventDetailsContainer {
    padding-top: 0px;
  }
  .thanksTitle {
    font-size: 35.12px;
    line-height: 20.89px;
    padding-bottom: 35px !important;
  }
  .competNameSection p {
    font-size: 22.12px;
    line-height: 35.78px;
  }
  .itsOfficialSec {
    /* padding: 40px 20px !important; */
  }
  .itsOfficial h1 {
    font-size: 22px;
    line-height: 26.89px;
  }
  .itsOfficial p {
    font-size: 12px;
    line-height: 20.45px;
  }
  .competInfoCol1 img {
    width: 100%;
  }
  .competInfoCol2 {
    /* width: 75% !important; */
  }
  .competInfoTitle {
    font-size: 20.44px;
    line-height: 20.36px;
  }
  .competeInfoSec h3 {
    font-size: 18.44px;
    line-height: 35.73px;
  }
  .competeInfoSec p {
    font-size: 15px;
    line-height: 16.45px;
  }
  .appDownloadBtn {
    width: 100%;
    font-size: 18px !important;
  }
  .otherCompetSec {
    padding-top: 10px !important;
  }
  .ifNoOthertitle {
    padding-top: 20px;
    font-size: 15px;
    line-height: 25.73px;
  }
  .confirmPaymentSec img {
    width: 150px;
  }
}
